/* You can add global styles to this file, and also import other style files */

@use "sass:map";
@use "@angular/material" as mat;

$primary-palette: (
  50: #e0ecf5,
  100: #b3cfe6,
  200: #80b0d6,
  300: #4d90c6,
  400: #2678b9,
  500: #0060ad,
  600: #0058a6,
  700: #004e9c,
  800: #004493,
  900: #003383,
  A100: #b0c8ff,
  A200: #7da5ff,
  A400: #4a82ff,
  A700: #3070ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

$accent-palette: (
  50: #fefefe,
  100: #fcfcfc,
  200: #fafafa,
  300: #f8f8f8,
  400: #f7f7f7,
  500: #f5f5f5,
  600: #f4f4f4,
  700: #f2f2f2,
  800: #f0f0f0,
  900: #eeeeee,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$theme-primary: mat.define-palette($primary-palette);
$theme-accent: mat.define-palette($accent-palette);
$theme-warn: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn
    ),
    typography: mat.define-typography-config()
  )
);

@include mat.core();
@include mat.core-theme($theme);
@include mat.all-component-themes($theme);

$font-headline: "Libre Baskerville", serif;
// $font-body: 'UniversLTPro-Roman', Arial, sans-serif;
// $font-body-bold: 'UniversLTPro-Bold', Arial, sans-serif;

:root {
  --font-headline: #{$font-headline};
  --color-blue: #{mat.get-color-from-palette($primary-palette, 600)};
  --color-white: #ffffff;
  --color-subtext: #767676;
  --color-border: rgba(0, 0, 0, 0.2);
  --color-background-grey: #{mat.get-color-from-palette($accent-palette, 500)};
  --color-icon-grey: rgba(0, 0, 0, 0.54);
  --color-background-white: #ffffff;
  --color-success: #457a1a;
  --color-warn: #b13741;
  --color-warn-yellow: #ffc107;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
